import { useGetAllContractors } from '@/react-query/ContractManagementSystemApi';
import { useTranslationText } from '@/translation/TranslationHooks';
import { CollapsibleWithButtons } from '@/ui/collapsible/CollapsibleWithButtons';
import { MaintenanceContractor } from '@/models/maintenance/MaintenanceContractor';
import SingleValueInput from '@/ui/single-value-input/SingleValueInput';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import FormFieldWrapper from '@/ui/form-field-wrapper/FormFieldWrapper';
import TextInput from '@/ui/text-input/TextInput';
import ListItemWrapper from '@/ui/list-item-wrapper/ListItemWrapper';

interface ContractorFormCollapsibleProps {
  involvedContractors: MaintenanceContractor[];
}

export function ContractorCollapsible({ involvedContractors }: Readonly<ContractorFormCollapsibleProps>) {
  const { t } = useTranslationText('maintenances');
  const { t: tCommon } = useTranslationText('commons');

  const { data: contractors } = useGetAllContractors();

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('involvedContractors')}
      badgeCount={involvedContractors.length}
    >
      {involvedContractors.length === 0 && tCommon('noItemsFoundAdd')}

      {involvedContractors.map((involvedContractor) => (
        <ListItemWrapper key={involvedContractor.contractorId} field="involvedContractors">
          <div className="columns is-align-items-flex-start">
            <div className="column is-12">
              <SingleValueInput
                options={
                  contractors?.content.map((contractor) => ({
                    label: contractor.companyName,
                    value: contractor.id,
                  })) ?? []
                }
                value={involvedContractor.contractorId}
                requiredPermission={UserResourcePermissions.MaintenanceRequest.Read}
                onChange={() => {}}
                isDisabled={true}
              />
            </div>
          </div>
          <div className="columns is-align-items-flex-start">
            <div className="column is-12">
              <CollapsibleWithButtons
                isInitiallyCollapsed={false}
                header={t('contactPersons')}
                badgeCount={involvedContractor.contactPersons.length}
              >
                {involvedContractor.contactPersons.length === 0 && tCommon('noItemsFoundAdd')}
                {involvedContractor.contactPersons.map((contactPerson, contactPersonIndex: number) => (
                  <div key={contactPerson.firstName + contactPerson.lastName + contactPerson.email}>
                    <div className="columns is-align-items-flex-start">
                      <div className="column is-12">
                        <FormFieldWrapper label={t('firstName')} isRequired>
                          <TextInput value={contactPerson.firstName} readOnly={true} />
                        </FormFieldWrapper>
                      </div>
                    </div>
                    <div className="columns is-align-items-flex-start">
                      <div className="column is-12">
                        <FormFieldWrapper label={t('lastName')} isRequired>
                          <TextInput value={contactPerson.lastName} readOnly={true} />
                        </FormFieldWrapper>
                      </div>
                    </div>
                    <div className="columns is-align-items-flex-start">
                      <div className="column is-12">
                        <FormFieldWrapper label={t('email')}>
                          <TextInput value={contactPerson.email} readOnly={true} />
                        </FormFieldWrapper>
                      </div>
                    </div>
                    <div className="columns is-align-items-flex-start">
                      <div className="column is-12">
                        <FormFieldWrapper label={t('phone')}>
                          <TextInput value={contactPerson.phone} readOnly={true} />
                        </FormFieldWrapper>
                      </div>
                    </div>
                  </div>
                ))}
              </CollapsibleWithButtons>
            </div>
          </div>
        </ListItemWrapper>
      ))}
    </CollapsibleWithButtons>
  );
}
