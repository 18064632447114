import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router';
import { yupResolver } from '@hookform/resolvers/yup';
import { Collapsible } from '@aos/styleguide-react';
import ActionBar from '../../ui/action-bar/ActionBar';
import { useTranslationText } from '@/translation/TranslationHooks';
import { CreateMaintenanceRequestDto } from '@/models/maintenance/Maintenance';
import { RollbackComplexity } from '@/models/maintenance/RollbackComplexity';
import { Priority } from '@/models/maintenance/Priority';
import ResponsibleForm from '../responsible/ResponsibleForm';
import DetailsForm from '../details/DetailsForm';
import { CreateContractorFormCollapsible } from '../contractor/CreateContractorFormCollapsible';
import './CreateMaintenanceRequest.scss';
import { CreateComponentFormCollapsible } from '../component/CreateComponentFormCollapsible';
import usePreventNavigation from '../../custom-hooks/PreventNavigation';
import { MutationKey, MutationPath, usePostMutation } from '@/react-query/MutationQueries';
import useYupLocal from '../../translation/YupLocal';
import maintenanceRequestValidationSchema from '../MaintenanceValidationSchema';
import { MaintenanceStatus } from '@/models/maintenance/MaintenanceStatus';

export default function CreateMaintenanceRequest() {
  const { yup } = useYupLocal();
  const [preventNavigation, setPreventNavigation] = useState(true);
  const { t } = useTranslationText('maintenances');
  const { t: tCommon } = useTranslationText('commons');
  const { t: tError } = useTranslationText('errorTexts');
  const navigate = useNavigate();

  const form = useForm<CreateMaintenanceRequestDto>({
    mode: 'onChange',
    resolver: yupResolver(maintenanceRequestValidationSchema(yup, tError)),
    defaultValues: {
      status: MaintenanceStatus.REQUEST,
      priority: Priority.LOW,
      startOfAction: new Date(),
      endOfAction: new Date(Date.now() + 60 * 60 * 1000),
      rollbackComplexity: RollbackComplexity.LOW,
      maintenanceLocations: [{ componentId: '' }],
      involvedContractors: [
        {
          contractorId: '',
          name: '',
          contactPersons: [
            {
              firstName: '',
              lastName: '',
              phone: '',
              email: '',
            },
          ],
        },
      ],
      personResponsible: {
        firstName: '',
        lastName: '',
        phone: '',
      },
      alternativeTimes: [],
    },
  });
  const { handleSubmit, control, formState, setValue } = form;

  usePreventNavigation(formState.isDirty && preventNavigation, tCommon('discardOpenChangesQuestion'));

  const { mutate } = usePostMutation<CreateMaintenanceRequestDto, CreateMaintenanceRequestDto>(
    MutationKey.PostMaintenanceRequest,
    {
      onSuccess: () => navigate(-1),
      onError: () => {
        toast.error(tError('createMaintenanceRequestError'));
      },
    },
  );

  const onSubmit = (maintenance: CreateMaintenanceRequestDto) => {
    setPreventNavigation(false);
    mutate({
      body: {
        title: maintenance.title,
        startOfAction: maintenance.startOfAction,
        endOfAction: maintenance.endOfAction,
        priority: maintenance.priority,
        priorityReason: maintenance.priorityReason,
        summary: maintenance.summary,
        expectedInfluenceOnSystems: maintenance.expectedInfluenceOnSystems,
        personResponsible: maintenance.personResponsible,
        involvedContractors: maintenance.involvedContractors,
        detailedDescription: maintenance.detailedDescription,
        maintenanceLocations: maintenance.maintenanceLocations,
        rollbackComplexity: maintenance.rollbackComplexity,
        rollbackPrecautions: maintenance.rollbackPrecautions,
        additionalComments: maintenance.additionalComments,
        alternativeTimes: maintenance.alternativeTimes,
      },
      path: MutationPath.CreateMaintenanceRequest,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex-container">
      <ActionBar
        right={
          <button
            className={classNames('button is-primary', { 'is-loading': false })}
            type="submit"
            aria-label="create-button"
            disabled={!formState.isValid || !formState.isDirty}
          >
            <span>{tCommon('save')}</span>
          </button>
        }
      />
      <div>
        <div className="columns is-align-items-flex-start no-overflow-content">
          <div className="column is-7">
            <Collapsible isInitiallyCollapsed={false} header={t('details')}>
              <DetailsForm form={form} />
            </Collapsible>
          </div>
          <div className="maintenance-contacts column is-5">
            <Collapsible isInitiallyCollapsed={false} header={t('responsible')}>
              <ResponsibleForm formState={formState} formControl={control} setValue={setValue} />
            </Collapsible>
            <CreateComponentFormCollapsible form={form} />
            <CreateContractorFormCollapsible form={form} />
          </div>
        </div>
      </div>
    </form>
  );
}
