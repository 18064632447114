import { Contract } from '@/models/contract-management/ContractManagementModel';
import ListItemWrapper from '@/ui/list-item-wrapper/ListItemWrapper';
import { useState } from 'react';
import { Button, Modal, ModalCard } from '@aos/styleguide-react';
import { useTranslationText } from '@/translation/TranslationHooks';
import NavigationRoutes from '@/routing/NavigationRoutes';
import { Link } from 'react-router-dom';
import { BaseComponentItem } from '@/models/operation/ComponentModel';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { ComponentPatchSubmit } from '@/models/patch/ComponentPatchSubmitType';

interface ComponentContractItemProps {
  contract: Contract;
  sourceComponent: BaseComponentItem;
  onDelete?: ComponentPatchSubmit;
}

export function ComponentContractItem({ contract, sourceComponent, onDelete }: ComponentContractItemProps) {
  const { t: tComponents } = useTranslationText('components');
  const { t: tCommon } = useTranslationText('commons');
  const [isModalOpen, setIsModalOpen] = useState(false);

  return (
    <>
      <ListItemWrapper
        mode="item"
        field="ComponentContractItem"
        onRemove={onDelete ? () => setIsModalOpen(true) : undefined}
        itemLink={NavigationRoutes.ContractId(contract.id)}
        deletePermission={UserResourcePermissions.Contract.Delete}
      >
        <div className="component-contract-item">
          <div className="contract">
            <span>
              {contract.internalNumber}, {contract.contractor.companyName}
            </span>
            <div className="source-info">
              <span className="spacer" />
              {onDelete ? (
                <>{tComponents('directlyAssigned')}</>
              ) : (
                <>
                  {tComponents('inheritedFrom')}{' '}
                  <Link to={NavigationRoutes.ComponentId(sourceComponent.id)}>{sourceComponent.displayName}</Link>
                </>
              )}
            </div>
          </div>
        </div>
      </ListItemWrapper>
      <Modal isActive={isModalOpen}>
        <ModalCard
          title={tComponents('contractRelationDelete')}
          content={tComponents('warningContractRelationDelete')}
          footer={
            <>
              <Button onClick={() => setIsModalOpen(false)}>{tCommon('back')}</Button>
              <Button isConfirm onClick={onDelete}>
                {tCommon('delete')}
              </Button>
            </>
          }
          onClose={() => setIsModalOpen(false)}
        />
      </Modal>
    </>
  );
}
