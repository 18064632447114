import { Controller, ControllerRenderProps, UseFormReturn } from 'react-hook-form';
import { useCallback, useEffect, useState } from 'react';
import SingleValueInput from '../../ui/single-value-input/SingleValueInput';
import { useTranslationText } from '../../translation/TranslationHooks';
import { UserResourcePermissions } from '../../auth/AuthUserRoles';
import { BaseMaintenance } from '../../models/maintenance/Maintenance';
import { MaintenanceContractor } from '../../models/maintenance/MaintenanceContractor';
import { Contractor } from '../../models/contract-management/ContractorModel';
import FormFieldWrapper from '../../ui/form-field-wrapper/FormFieldWrapper';
import ListItemWrapper from '../../ui/list-item-wrapper/ListItemWrapper';
import { Page } from '../../models/pagination/Pagination';
import { ContactPersonFormCollapisble } from './contact-person/ContactPersonFormCollapsible';

interface ContractorFormProps {
  form: UseFormReturn<BaseMaintenance>;
  onRemove: () => void;
  index: number;
  contractors: Page<Contractor> | undefined;
  readonly: boolean;
}

export default function ContractorForm({
  form,
  onRemove,
  index,
  contractors,
  readonly: readOnly,
}: ContractorFormProps) {
  const { control, formState, setValue } = form;
  const { t } = useTranslationText('maintenances');

  const [selectedContractor, setSelectedContractor] = useState<Contractor | undefined>();

  useEffect(() => {
    if (!readOnly) {
      setValue(`involvedContractors.${index}.contractorId`, selectedContractor?.id || '');
      setValue(`involvedContractors.${index}.name`, selectedContractor?.companyName || '');
    }
  }, [selectedContractor, readOnly, index, setValue]);

  const getErrors = useCallback(
    (field: keyof MaintenanceContractor) => {
      if (field === 'contactPersons') {
        return undefined;
      }
      return formState.errors?.involvedContractors !== undefined &&
        formState.errors?.involvedContractors[index] !== undefined
        ? formState?.errors?.involvedContractors.at?.(index)?.[field]
        : undefined;
    },
    [formState.errors?.involvedContractors, index],
  );

  const ContractorInput = useCallback(
    ({
      field,
    }: {
      field: ControllerRenderProps<BaseMaintenance, `involvedContractors.${typeof index}.contractorId`>;
    }) => (
      <FormFieldWrapper error={getErrors('contractorId')} label={t('contractor')} isRequired>
        <SingleValueInput
          options={
            contractors?.content.map((contractor) => ({ label: contractor.companyName, value: contractor.id })) ?? []
          }
          value={field.value}
          requiredPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
          key={`maintenance-request-contractor-${index}`}
          onChange={(value) => {
            const contractor: Contractor | undefined = contractors?.content?.find((c) => c.id === value);
            setSelectedContractor(contractor);
          }}
          isDisabled={readOnly}
        />
      </FormFieldWrapper>
    ),
    [contractors, readOnly, index, t, getErrors],
  );

  return (
    <ListItemWrapper
      mode="item"
      field="involdvedContractors"
      index={index}
      onRemove={index !== 0 ? onRemove : undefined}
      deletePermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      deleteDisabled={readOnly}
      key={`listItemWrapper-${index}-${selectedContractor?.id}`}
    >
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <Controller name={`involvedContractors.${index}.contractorId`} control={control} render={ContractorInput} />
        </div>
      </div>
      <div className="columns is-align-items-flex-start">
        <div className="column is-12">
          <ContactPersonFormCollapisble form={form} index={index} readonly={readOnly} />
        </div>
      </div>
    </ListItemWrapper>
  );
}
