import { mapContractItemsToOptions } from '@/models/contract-management/ContractFunctions';
import { ComponentItem } from '@/models/operation/ComponentModel';
import { useTranslationText } from '@/translation/TranslationHooks';
import { ComponentPatchSubmit } from '@/models/patch/ComponentPatchSubmitType';
import ContractSearchDropdown from './ContractSearchDropdown';
import { useState } from 'react';
import { Contract } from '@/models/contract-management/ContractManagementModel';

interface AssignContractItemProps {
  component: ComponentItem;
  contracts: Contract[];
  onSubmit: ComponentPatchSubmit;
}

export function AssignContractItem({ component, contracts, onSubmit }: AssignContractItemProps) {
  const { t } = useTranslationText('components');
  const [selectedContract, setSelectedContract] = useState<Contract | null>(
    contracts.find((contract) => contract.id === component.assignedContractId) ?? null,
  );

  return (
    <div className="contract-item">
      <ContractSearchDropdown
        contract={selectedContract}
        options={mapContractItemsToOptions(contracts)}
        onChange={(value) => {
          if (component?.assignedContractId === value?.value) return;
          onSubmit(value?.value, 'assignedContractId', true);
          setSelectedContract(value?.value);
        }}
        label={t('assignedContract')}
        placeholder={t('noAssignedContract')}
      />
    </div>
  );
}
