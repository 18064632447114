import { useTranslationText } from '@/translation/TranslationHooks';
import { ComponentIdWithLocation } from '@/models/maintenance/ComponentIdWithLocation';
import { CollapsibleWithButtons } from '@/ui/collapsible/CollapsibleWithButtons';
import ListItemWrapper from '@/ui/list-item-wrapper/ListItemWrapper';
import SingleComponentSelect from '@/components/component-selects/SingleComponentSelect/SingleComponentSelect';
import useGetComponentsSummarized from '@/react-query/ComponentApi';
import { SummarizedComponent } from '@/models/operation/ComponentModel';
import TextArea from '@/ui/text-area/TextArea';
import FormFieldWrapper from '@/ui/form-field-wrapper/FormFieldWrapper';

function findComponentByIdInArray(id: string, componentArray: SummarizedComponent[]): SummarizedComponent | null {
  return componentArray.find((component) => component.id === id) || null;
}

interface ComponentAndLocationCollapsibleProps {
  componentIdWithLocationArray: ComponentIdWithLocation[];
}

export function ComponentAndLocationCollapsible({
  componentIdWithLocationArray,
}: Readonly<ComponentAndLocationCollapsibleProps>) {
  const { t } = useTranslationText('maintenances');
  const { t: tCommon } = useTranslationText('commons');
  const { t: tError } = useTranslationText('errorTexts');
  const { data: components } = useGetComponentsSummarized(
    {},
    {
      componentsIds: componentIdWithLocationArray.map((componentIdWithLocation) => componentIdWithLocation.componentId),
    },
  );

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('components')}
      badgeCount={componentIdWithLocationArray.length}
    >
      {componentIdWithLocationArray.length === 0 && tCommon('noItemsFoundAdd')}
      {componentIdWithLocationArray.map((componentIdWithLocation, index: number) => (
        <ListItemWrapper
          key={componentIdWithLocation.componentId}
          mode="item"
          field="physicalLocationOfComponents"
          index={index}
        >
          <div className="columns is-align-items-flex-start">
            <div className="column is-12">
              <SingleComponentSelect
                onChange={() => {}}
                value={findComponentByIdInArray(componentIdWithLocation.componentId, components?.content || [])}
                key={`maintenance-request-component-${index}`}
                isDisabled={true}
              />
              {findComponentByIdInArray(componentIdWithLocation.componentId, components?.content || []) === null && (
                <div>{tError('404_component', { id: componentIdWithLocation.componentId })}</div>
              )}
            </div>
          </div>
          <div className="columns is-align-items-flex-start">
            <div className="column is-12">
              <FormFieldWrapper label={t('locationDescription')}>
                <TextArea
                  key={`maintenance-request-location-description-${index}`}
                  value={componentIdWithLocation.description}
                  readOnly={true}
                />
              </FormFieldWrapper>
            </div>
          </div>
        </ListItemWrapper>
      ))}
    </CollapsibleWithButtons>
  );
}
