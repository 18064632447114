import { ToastContainer } from 'react-toastify';
import { useAuth } from 'react-oidc-context';
import { Footer as AosFooter, Layout as AosLayout } from '@aos/styleguide-react';
import Navigation from '../navigation/Navigation';
import StreetLoadingSpinner from '../street-loading-spinner/StreetLoadingSpinner';
import { DropZoneProvider } from '@/DropZoneContext';
import CookieAuthManager from '../../CookieAuthManager';
import TitleBar from '../title-bar/TitleBar';
import ContentWrapper from '../../ContentWrapper';
import { useTranslationInit } from '@/translation/TranslationHooks';
import DemoModal from '../modal/DemoModal';
import './Layout.scss';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import { useGetChangelog } from '@/react-query/GetChangelogApi';
import { formatTenant } from '../title-bar-items/TenantMenu';

function ContentWithInitializedTranslations() {
  const { currentTenant } = useCurrentTenant();

  const { data: changelog } = useGetChangelog();

  // Regex removes '[', ']' and 'v'; slice cuts the string after the version number in order to remove the date
  const version = changelog?.version.replace(/[[\]v]/g, '').slice(0, changelog.version.indexOf('-') - 3);
  const environment = changelog?.environment;

  const handleCopyClick = () => {
    const textToCopy = `AutobahnOS | BMS zentral | ${formatTenant(currentTenant)} | ${environment ?? '?'} | Version: ${version ?? '?'}`;
    void navigator.clipboard.writeText(textToCopy);
  };

  return (
    <>
      <CookieAuthManager />
      <DemoModal />
      <AosLayout
        titleBar={<TitleBar />}
        navigation={<Navigation />}
        content={
          <DropZoneProvider>
            <ContentWrapper />
          </DropZoneProvider>
        }
        footer={
          <AosFooter
            fachanwendung="AutobahnOS | BMS zentral |"
            vzOrt={formatTenant(currentTenant)}
            systemumgebung={environment ?? '?'}
            softwareVersion={version ?? '?'}
            onCopyClick={handleCopyClick}
          />
        }
      />
      <ToastContainer position="top-center" />
    </>
  );
}

export default function Layout() {
  const [isI18nInit, isLoadingI18] = useTranslationInit();
  const auth = useAuth();

  return (
    <StreetLoadingSpinner isLoading={auth.isLoading || !isI18nInit || isLoadingI18}>
      <ContentWithInitializedTranslations />
    </StreetLoadingSpinner>
  );
}
