import queryString from 'query-string';
import { QueryOptions, useGetQuery } from './GetQuery';
import ServerStateKeysEnum from './ServerStateKeysEnum';

interface ChangelogQuery {
  version?: boolean;
  environment?: boolean;
  patchnotes?: boolean;
}

export default interface Changelog {
  version: string;
  environment: Environment;
  patchnotes: string;
}

export enum Environment {
  Test = 'Test',
  LiseTest = 'LiseTest',
  Ref = 'Ref',
  Prod = 'Prod',
  Demo = 'Demo',
}

export function useGetChangelog(options?: QueryOptions<Changelog>, query?: ChangelogQuery) {
  const queryParams = query ? `?${queryString.stringify(query)}` : '';
  return useGetQuery<Changelog>(
    [ServerStateKeysEnum.Changelog, queryParams],
    `/api/knowledgeBase/changelog${queryParams}`,
    options,
  );
}
