import { useFieldArray, UseFormReturn } from 'react-hook-form';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import { BaseMaintenance } from '@/models/maintenance/Maintenance';
import { useGetAllContractors } from '@/react-query/ContractManagementSystemApi';
import { useTranslationText } from '@/translation/TranslationHooks';
import ContractorForm from './ContractorForm';
import { CollapsibleWithButtons } from '@/ui/collapsible/CollapsibleWithButtons';

interface ContractorFormCollapsibleProps {
  form: UseFormReturn<BaseMaintenance>;
  readOnly?: boolean;
}

export function CreateContractorFormCollapsible({ form, readOnly = false }: Readonly<ContractorFormCollapsibleProps>) {
  const { t } = useTranslationText('maintenances');
  const { t: tCommon } = useTranslationText('commons');
  const { control } = form;
  const { append, fields, remove } = useFieldArray({ control, name: `involvedContractors` });

  const { data: contractors } = useGetAllContractors();

  return (
    <CollapsibleWithButtons
      isInitiallyCollapsed={false}
      header={t('involvedContractors')}
      badgeCount={fields.length}
      addBtnClicked={() =>
        append({
          contractorId: '',
          name: '',
          contactPersons: [],
        })
      }
      addPermission={UserResourcePermissions.MaintenanceRequest.CreateOrUpdate}
      addButtonDisabled={readOnly}
    >
      {fields.length === 0 && tCommon('noItemsFoundAdd')}
      {fields.map((field, index: number) => (
        <ContractorForm
          key={field.id}
          form={form}
          onRemove={() => remove(index)}
          index={index}
          contractors={contractors}
          readonly={readOnly}
        />
      ))}
    </CollapsibleWithButtons>
  );
}
