import dayjs from 'dayjs';
import { Trans } from 'react-i18next';
import DateFormats from '../../../models/date-formats/DateFormats';
import { useTranslationText } from '@/translation/TranslationHooks';
import './HistoryItem.scss';
import { HistoryModel } from '@/models/operation/TicketModel';
import PayloadFactory from './UpdateItemComponents';
import { TicketSeverities } from '@/models/operation/TicketSeveritiesModel';
import { TicketStates } from '@/models/operation/TicketStatesModel';
import ActorLabel from '../../../ui/actor-label/ActorLabel';

interface HistoryItemProps extends HistoryModel {
  readonly severities?: TicketSeverities;
  readonly states?: TicketStates;
}

export default function HistoryItem({
  eventTranslationKey,
  actor,
  createdAt,
  payload,
  testId,
  severities = [],
  states = [],
}: HistoryItemProps) {
  const { t } = useTranslationText('histories');

  return (
    <div className="history-item" data-role="history-item">
      <div>
        <span className="highlight">
          <ActorLabel actor={actor} emailAddress={payload?.emailAddress} />
        </span>
        <Trans
          i18nKey="histories.userDidAtDate.text"
          values={{
            date: ` ${dayjs(createdAt).format(DateFormats.FULLDATE_HOURS_MINS)}`,
            fieldName: payload?.fieldTranslationKey ? t(payload?.fieldTranslationKey) : '',
            action: t(eventTranslationKey),
          }}
          components={{
            1: <span className="task-highlight" />,
            2: <span className="action-highlight" />,
            3: <span className="date-highlight" />,
          }}
        />
      </div>
      {payload && !payload?.emailAddress && (
        <div className="history-event">
          <PayloadFactory payload={payload} testId={testId} severities={severities} states={states} />
        </div>
      )}
    </div>
  );
}
