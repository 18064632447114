export function nowPlus({ days, hours }: { days?: number; hours?: number } = { days: 0, hours: 0 }): Date {
  const hour = 1000 * 60 * 60;
  const day = hour * 24;

  let nowPlusX = Date.now();
  nowPlusX += (hours ?? 0) * hour;
  nowPlusX += (days ?? 0) * day;

  return new Date(nowPlusX);
}
