import { AssignableActor } from '@/models/actor/Actor';
import { useState } from 'react';
import { useGetAssignableActors } from '@/react-query/TicketingSystemApi';
import { Page } from '@/models/pagination/Pagination';
import { QueryOptions } from './GetQuery';

export default function useGetAllAssignableActors(
  userIds?: string[],
  emails?: string[],
  options?: QueryOptions<Page<AssignableActor>>,
) {
  const [query, setQuery] = useState('');
  const [size, setSize] = useState<number>();
  const [page, setPage] = useState<number>();

  const { data, isLoading, isError } = useGetAssignableActors(options, {
    query,
    userIds,
    emails,
    size: size?.toString(),
    page: page?.toString(),
  });

  return {
    data,
    isLoading,
    isError,
    query,
    setQuery,
    setSize,
    setPage,
  };
}
