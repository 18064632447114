import { useState } from 'react';
import { OptionsOrGroups } from 'react-select';
import { BulmaSize } from '@aos/styleguide-react/dist/common/constants';
import { FieldError, Merge } from 'react-hook-form';
import { useTranslationText } from '@/translation/TranslationHooks';
import useCustomFetch from '../../../react-query/CustomFetch';
import { BaseComponentItem } from '@/models/operation/ComponentModel';
import { useCurrentTenant } from '@/user/tenant-context/CurrentTenantContext';
import Select from '../../../ui/select/Select';
import { UserResourcePermissions } from '@/auth/AuthUserRoles';
import '../ComponentSelect.scss';
import { ComponentOptionLabel, loadComponentsOptions, mapComponentToOption } from '../ComponentSelectUtils';
import { mapOptionToTValue, SelectOption, SelectPaginationAdditional } from '@/ui/select/SelectUtils';

interface SingleComponentSelectProps {
  readonly onChange: (component: BaseComponentItem | null) => void;
  readonly value: BaseComponentItem | null;
  readonly label?: string;
  readonly disableLabel?: boolean;
  readonly isRequired?: boolean;
  readonly isDisabled?: boolean;
  readonly size?: BulmaSize;
  readonly error?: FieldError | Merge<FieldError, (FieldError | undefined)[]>;
}

export default function SingleComponentSelect({
  onChange,
  value,
  label,
  disableLabel,
  isRequired,
  isDisabled,
  size,
  error,
}: SingleComponentSelectProps) {
  const { t } = useTranslationText('components');
  const customFetch = useCustomFetch();
  const { currentTenant } = useCurrentTenant();

  const [isError, setIsError] = useState(false);

  const loadOptions = async (
    searchInput: string,
    _prev: OptionsOrGroups<SelectOption<BaseComponentItem>, never>,
    pageToLoad?: SelectPaginationAdditional,
  ) => loadComponentsOptions(searchInput, pageToLoad ?? { page: 0 }, setIsError, customFetch);

  return (
    <Select<BaseComponentItem, never, SelectPaginationAdditional, false>
      onChange={(newValue) => {
        onChange(mapOptionToTValue<BaseComponentItem>(newValue));
      }}
      value={value === null ? null : mapComponentToOption(value)}
      loadOptions={loadOptions}
      openMenuOnClick={false}
      noOptionsMessage={() => t('noComponentForSearch')}
      loadingMessage={() => t('componentsAreLoading')}
      placeholder={t('emptyComponentSearchField')}
      requiredPermission={UserResourcePermissions.Component.Read}
      isClearable
      label={label ?? t('assignedComponent')}
      isRequired={isRequired}
      isDisabled={isDisabled}
      isError={isError}
      cacheUniqs={[currentTenant]}
      formatOptionLabel={(opt, metadata) => (
        <ComponentOptionLabel payload={opt.payload} isOption={metadata.context === 'menu'} />
      )}
      size={size}
      additional={{ page: 0 }}
      error={error}
      disableLabel={disableLabel}
    />
  );
}
