import { Collapsible } from '@aos/styleguide-react';
import LoadingSpinner from '@/ui/loading-spinner/LoadingSpinner';
import { useGetAllContracts, useGetContractById } from '@/react-query/ContractManagementSystemApi';
import { ComponentContractItem } from '@/components/component-contract-item/ComponentContractItem';
import { ComponentItem } from '@/models/operation/ComponentModel';
import { ComponentPatchSubmit } from '@/models/patch/ComponentPatchSubmitType';
import { AssignContractItem } from '@/components/component-contract-item/AssignContractItem';
import { useGetAncestorComponents } from '@/react-query/ComponentApi';
import { Contract } from '@/models/contract-management/ContractManagementModel';
import { Fragment } from 'react';

interface ComponentContractsProps {
  title: string;
  component: ComponentItem;
  onSubmit: ComponentPatchSubmit;
}

export function ComponentContracts({ title, component, onSubmit }: ComponentContractsProps) {
  const { data: contract, isLoading: isLoadingContract } = useGetContractById(component.assignedContractId!, {
    enabled: !!component.assignedContractId,
  });

  const { data: ancestors = [], isLoading: areAncestorsLoading } = useGetAncestorComponents(component.id, 'part-of');
  const ancestorsWithAssignedContracts = (ancestors || []).filter((ancestor) => !!ancestor.assignedContractId);

  const { data: contracts, isLoading: isContractsLoading } = useGetAllContracts();
  const ancestorsContractsCandidates: (Contract | null | undefined)[] = [];
  ancestorsWithAssignedContracts.forEach((ancestorWithAssignedContract) =>
    ancestorsContractsCandidates.push(
      contracts?.content.find((contract) => ancestorWithAssignedContract.assignedContractId === contract.id),
    ),
  );
  const ancestorsContracts: Contract[] =
    (ancestorsContractsCandidates.filter((contract) => contract !== null && contract !== undefined) as Contract[]) ??
    [];

  const badgeCount = (ancestorsContracts.length || 0) + (contract ? 1 : 0);

  return (
    <Collapsible header={title} badgeCount={badgeCount} isInitiallyCollapsed={false}>
      <LoadingSpinner isLoading={isLoadingContract}>
        {contract ? (
          <ComponentContractItem
            contract={contract}
            sourceComponent={component}
            onDelete={() => onSubmit!(undefined, 'assignedContractId', true)}
          />
        ) : (
          <AssignContractItem component={component} contracts={contracts?.content || []} onSubmit={onSubmit} />
        )}
        <LoadingSpinner isLoading={areAncestorsLoading || isContractsLoading}>
          {contracts?.content &&
            ancestorsWithAssignedContracts.map((ancestor) => {
              const ancestorsContract = contracts.content.find((c) => c.id === ancestor.assignedContractId);
              return (
                ancestorsContract && (
                  <Fragment key={ancestorsContract.id}>
                    <ComponentContractItem contract={ancestorsContract} sourceComponent={ancestor} />
                  </Fragment>
                )
              );
            })}
        </LoadingSpinner>
      </LoadingSpinner>
    </Collapsible>
  );
}
